import { IonHeader, IonContent, IonToolbar, IonTitle, IonButtons, IonIcon, IonButton, IonCard, IonCardHeader, IonCardSubtitle, IonCardContent, IonCardTitle, IonPage } from '@ionic/vue';
import { arrowBackOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
export default defineComponent({
    components: {
        IonHeader,
        IonContent,
        IonToolbar,
        IonTitle,
        IonButtons,
        IonIcon,
        IonButton,
        IonCard,
        IonCardHeader,
        IonCardSubtitle,
        IonCardContent,
        IonCardTitle,
        IonPage
    },
    setup() {
        const router = useRouter();
        return {
            handleBack: router.back,
            arrowBackOutline,
        };
    }
});
